import Fade from "react-reveal/Fade";
import clock from "../assets/images/time.svg"

export default function intro(props){
    return(
        <div className="intro">
            <div className="intro__etoile1"> </div>
            <div className="intro__etoile2"> </div>
            <div className="intro__etoile3"> </div>
            <div className="intro__fond"> </div>
            <div className="intro__bottom"> </div>
            <div className="container intro__container">
                <Fade top><p className="intro__title">Suivez toutes les infos de l'ISS en temps réel avec TrackSpace !</p></Fade>
                <div className="intro__hours">
                    <div className="intro__hours__iss">
                        <img src={clock} alt=""/>
                        {props.issHours ?
                            <div>
                                <span>ISS</span>
                                <span>{props.issHours} : {props.issMinutes} : {props.issSeconds}</span>
                            </div>
                        : ""}
                    </div>
                    <div className="intro__hours__local">
                        <img src={clock} alt=""/>
                        {props.localHours ?
                            <div>
                                <span>France</span>
                                <span>{props.localHours} : {props.localMinutes} : {props.localSeconds}</span>
                            </div>
                        : ""}
                    </div>
                </div>
            </div>
        </div>
    );
}