import astro1 from '../assets/images/Shane_Kimbrough.jpg';
import astro2 from '../assets/images/Megan_McArthur.jpg';
import astro3 from '../assets/images/Mark_Vande_Hei.jpg';
import astro4 from '../assets/images/Oleg_Novitskiy.jpg';
import astro5 from '../assets/images/Pyotr Dubrovnik.jpg';
import astro6 from '../assets/images/Thomas_Pesquet.jpg';
import astro7 from '../assets/images/Hoshide.jpg';
import fra from '../assets/images/fra.svg';
import jpn from '../assets/images/jpn.svg';
import rus from '../assets/images/rus.svg';
import usa from '../assets/images/usa.svg';
import Fade from "react-reveal/Fade";
import SingleEquipage from "./SingleEquipage";

export default function Equipage(){
    return(
        <div className="equipage">
            <div className="equipage__degrade"> </div>
            <div className="container equipage__container">
                <p className="equipage__title">7 astronautes sont à son bord...</p>
                <div className="equipage__members">
                    <Fade top>
                        <SingleEquipage name="Shane Kimbrough" imgAstro={astro1} imgPays={usa} diminutif="USA" service="NASA"/>
                        <SingleEquipage name="Megan McArthur" imgAstro={astro2} imgPays={usa} diminutif="USA" service="NASA"/>
                        <SingleEquipage name="Mark Vande Hei" imgAstro={astro3} imgPays={usa} diminutif="USA" service="NASA"/>
                        <SingleEquipage name="Oleg Novitskiy" imgAstro={astro4} imgPays={rus} diminutif="RUS" service="BBC"/>
                        <SingleEquipage name="Pyotr Dubrovnik" imgAstro={astro5} imgPays={rus} diminutif="RUS" service="BBC"/>
                        <SingleEquipage name="Thomas Pesquet" imgAstro={astro6} imgPays={fra} diminutif="FRA" service="ESA"/>
                        <SingleEquipage name="Hoshide" imgAstro={astro7} imgPays={jpn} diminutif="JPN" service="JAXA"/>

                    </Fade>
                </div>
            </div>
        </div>
    )
}