export default function SingleEquipage(props){
    return(
        <div className="equipage__members__single">
            <div className="equipage__members__single--front">
                <div className="photo">
                    <img src={props.imgAstro} alt=""/>
                </div>
                <div className="infos">
                    <p>{props.name}</p>
                    <div><img src={props.imgPays} alt=""/><p>{props.diminutif}</p></div>
                    <p>{props.service}</p>
                </div>
            </div>
        </div>
    )
}