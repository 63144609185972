import React, {useEffect} from "react";
import { Cartesian3 } from "cesium";
import {Viewer, Entity, CameraFlyTo, BillboardGraphics, Scene, SkyAtmosphere} from "resium";
import * as Cesium from "cesium";
import logoiss from "../assets/images/iss.svg";
Cesium.Ion.defaultAccessToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI0YWJmOGI4MC1kODY5LTQ3NDAtYTM4OS0zMjUzOTI1ZDJkNWYiLCJpZCI6NjgwNTgsImlhdCI6MTYzMjMwODEwNH0.uv_IpaNa-ZC6K4SVlft6CupJUpXy3JHvSpVh3Tov4GI";
export default function Map(props){

    useEffect(()=>{
        return () =>  null;
    },[]);
    return (
        <div className="mapZone">
                <div className="mapZone__degrade"><h2>{props.codePays === "??" ? <>L'ISS se trouve actuellement au dessus de l'eau</> : <>Position actuelle de l'ISS&nbsp;: {props.localisation}</>}</h2> </div>

                    <Viewer
                        homeButton={false}
                        animation={false}
                        baseLayerPicker={false}
                        fullscreenButton={false}
                        geocoder={false}
                        infoBox={false}
                        sceneModePicker={false}
                        selectionIndicator={false}
                        timeline={false}
                        useDefaultRenderLoop={true}
                        navigationHelpButton={false}
                        scene3DOnly={true}
                        shouldAnimate={true}
                        sun={false}
                        skyBox={false}
                        skyAtmosphere={false}
                        requestRenderMode={false}

                    >
                        <p className="vitesse">{props.vitesseActuelle}<span>km/h</span></p>
                        <div className="infosMeteo">
                            <img className="logoMeteo" src={props.logoMeteo} alt=""/>
                            <p>{props.temperature}°C<br />{window.innerWidth >= 700 ? <pre>(t° en dessous de l'ISS)</pre> : ""}</p>
                        </div>

                        <Scene backgroundColor={Cesium.Color.TRANSPARENT} />
                        <SkyAtmosphere show={true} />
                        {window.innerWidth > 700 ?
                            <CameraFlyTo destination={Cartesian3.fromDegrees(props.tracking[0]+5, props.tracking[1]-20, props.tracking[2] * 45000)}/>
                            : <CameraFlyTo destination={Cartesian3.fromDegrees(props.tracking[0]+5, props.tracking[1]-20, props.tracking[2] * 25000)}/> }

                        <Entity
                            position={Cartesian3.fromDegrees(props.tracking[0], props.tracking[1], props.tracking[2] * 100)}

                        >
                            <BillboardGraphics image={logoiss} scale={0.3}/>
                        </Entity>
                    </Viewer>

                    <div className="mapZone__cache"> </div>

            </div>
        );
}


