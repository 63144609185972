import Map from "./components/Map";
import Intro from "./components/Intro";
import Header from "./components/Header";
import {createRef, useEffect, useState} from "react";
import axios from "axios";
import Equipage from "./components/Equipage";
import Footer from "./components/Footer";
import lottie from "lottie-web";
import loader from './assets/json/loader.json';

function App() {
    const [issHours, setIssHours] = useState();
    const [issMinutes, setIssMinutes] = useState();
    const [issSeconds, setIssSeconds] = useState();
    const [localHours, setLocalHours] = useState();
    const [localMinutes, setLocalMinutes] = useState();
    const [localSeconds, setLocalSeconds] = useState();
    const [localisation, setLocalisation] = useState();
    const [vitesseActuelle, setVitesseActuelle] = useState();
    const [codePays, setCodePays] = useState();
    const [tracking,setTracking] = useState([0,0,0]);
    const [loading,setLoading] = useState(true);
    const [logoMeteo, setLogoMeteo] = useState();
    const [temperature, setTemperature] = useState();

    let animationContainer = createRef();

    async function fetchData(){
        let rep = await axios.get('https://api.wheretheiss.at/v1/satellites/25544');
        return rep;
    }
    async function fetchDataLocalisation(lat,lon){
        let rep = await axios.get('https://api.wheretheiss.at/v1/coordinates/'+lat+','+lon);
        return rep;
    }

    useEffect(()=>{
        document.body.style.overflowY = "hidden";
        setTimeout(()=>{
            setLoading(false);
            document.body.style.overflowY = "auto";
        },3000);
        setTimeout(()=>{
            document.querySelector('.animation-container').style.display = "none";
        },6000);
        const anim = lottie.loadAnimation({
            container: animationContainer.current,
            renderer: "svg",
            loop: false,
            autoplay: true,
            animationData: loader
        });
        fetchData().then((donnees)=>{
            setTracking([donnees.data.longitude,donnees.data.latitude,donnees.data.altitude]);
            setVitesseActuelle(parseInt(donnees.data.velocity));
            console.log(donnees.data.longitude,donnees.data.latitude,donnees.data.altitude);
            fetchDataLocalisation(donnees.data.latitude,donnees.data.longitude).then((result)=>{
                console.log(result.data);
                setCodePays(result.data.country_code);
                axios.get('https://restcountries.com/v3/alpha/'+result.data.country_code).then((rep)=>{
                    console.log(rep.data[0].translations.fra.common);
                    setLocalisation(rep.data[0].translations.fra.common);
                });
                axios.get('https://api.openweathermap.org/data/2.5/weather?lat='+donnees.data.latitude+'&lon='+donnees.data.longitude+'&lang=fr&units=metric&appid=ead90cdb19b6064eadf1dadca3c56fbc').then((response)=>{
                    setTemperature(parseInt(response.data.main.temp));
                    setLogoMeteo("https://openweathermap.org/img/wn/"+response.data.weather[0].icon+"@4x.png")
                });
            })
        });
        const interval = setInterval(() => {
            fetchData().then((donnees)=>{
                let hoursISS = new Date(Date.now()+(new Date().getTimezoneOffset()*60000)).getHours();
                let hoursLocal = new Date().getHours();
                if(hoursISS < 10){
                    hoursISS = "0"+hoursISS;
                }
                if(hoursLocal < 10){
                    hoursLocal = "0"+hoursLocal;
                }
                let minutesISS = new Date(Date.now()+(new Date().getTimezoneOffset()*60000)).getMinutes();
                let minutesLocal = new Date(Date.now()+(new Date().getTimezoneOffset()*60000)).getMinutes();
                if(minutesISS < 10){
                    minutesISS = "0"+minutesISS;
                }
                if(minutesLocal < 10){
                    minutesLocal = "0"+minutesLocal;
                }
                let secondsISS = new Date(Date.now()+(new Date().getTimezoneOffset()*60000)).getSeconds();
                let secondsLocal = new Date().getSeconds();
                if(secondsISS < 10){
                    secondsISS = "0"+secondsISS;
                }
                if(secondsLocal < 10){
                    secondsLocal = "0"+secondsLocal;
                }
                setIssHours(hoursISS);
                setIssMinutes(minutesISS);
                setIssSeconds(secondsISS);
                setLocalHours(hoursLocal);
                setLocalMinutes(minutesLocal);
                setLocalSeconds(secondsLocal);

                setTracking([donnees.data.longitude,donnees.data.latitude,donnees.data.altitude]);
                setVitesseActuelle(parseInt(donnees.data.velocity));

                fetchDataLocalisation(donnees.data.latitude,donnees.data.longitude).then((result)=>{
                    console.log(result.data)
                    setCodePays(result.data.country_code);
                    if(result.data.country_code !== "??"){
                        axios.get('https://restcountries.com/v3/alpha/'+result.data.country_code).then((rep)=>{
                            console.log(rep.data[0].translations.fra.common);
                            setLocalisation(rep.data[0].translations.fra.common);
                        });
                        axios.get('https://api.openweathermap.org/data/2.5/weather?lat='+donnees.data.latitude+'&lon='+donnees.data.longitude+'&lang=fr&units=metric&appid=ead90cdb19b6064eadf1dadca3c56fbc').then((response)=>{
                            setTemperature(parseInt(response.data.main.temp));
                            setLogoMeteo("https://openweathermap.org/img/wn/"+response.data.weather[0].icon+"@4x.png")
                        });
                    }
                })
            });
        }, 5000);
        const intervalTime = setInterval(() => {
            let hoursISS = new Date(Date.now()+(new Date().getTimezoneOffset()*60000)).getHours();
            let hoursLocal = new Date().getHours();
            if(hoursISS < 10){
                hoursISS = "0"+hoursISS;
            }
            if(hoursLocal < 10){
                hoursLocal = "0"+hoursLocal;
            }
            let minutesISS = new Date(Date.now()+(new Date().getTimezoneOffset()*60000)).getMinutes();
            let minutesLocal = new Date(Date.now()+(new Date().getTimezoneOffset()*60000)).getMinutes();
            if(minutesISS < 10){
                minutesISS = "0"+minutesISS;
            }
            if(minutesLocal < 10){
                minutesLocal = "0"+minutesLocal;
            }
            let secondsISS = new Date(Date.now()+(new Date().getTimezoneOffset()*60000)).getSeconds();
            let secondsLocal = new Date().getSeconds();
            if(secondsISS < 10){
                secondsISS = "0"+secondsISS;
            }
            if(secondsLocal < 10){
                secondsLocal = "0"+secondsLocal;
            }
            setIssHours(hoursISS);
            setIssMinutes(minutesISS);
            setIssSeconds(secondsISS);
            setLocalHours(hoursLocal);
            setLocalMinutes(minutesLocal);
            setLocalSeconds(secondsLocal);
        }, 1000);
        return () => clearInterval(interval);
    },[]);
  return (
    <div className="App">
        <div ref={animationContainer} className={loading === false ? "animation-container hide" : "animation-container"} />
        <Header />
        <Intro issHours={issHours} issMinutes={issMinutes} issSeconds={issSeconds} localHours={localHours} localMinutes={localMinutes} localSeconds={localSeconds}/>
        <Map tracking={tracking} localisation={localisation} codePays={codePays} vitesseActuelle={vitesseActuelle} logoMeteo={logoMeteo} temperature={temperature}/>
        <Equipage />
        <Footer />
    </div>
  );
}

export default App;
