import logo from '../assets/images/trackspace.png'
import Hamburger from 'hamburger-react'
import {useState} from "react";
export default function Header(){
    const [isOpen, setOpen] = useState(false);
    function goTo(selector){
        document.querySelector(selector).scrollIntoView();
        setOpen(false);
    }
    return(
        <div className="header">
            <div className="header__content">
                <img onClick={()=>goTo('.intro')} src={logo} alt="Logo TrackSpace"/>
                <div className={window.innerWidth <=700 ? isOpen ? "header__content__links open" : "header__content__links close" : "header__content__links" }>
                    <a onClick={()=>goTo('.intro')}>Heure</a>
                    <a onClick={()=>goTo('.mapZone')}>Position</a>
                    <a onClick={()=>goTo('.equipage')}>Equipage</a>
                </div>
                {window.innerWidth <= 700 ? <Hamburger toggled={isOpen} toggle={setOpen} direction="right" size={30} rounded /> : ""}

            </div>
        </div>
    )
}