import logo from '../assets/images/ts.svg';
export default function Footer(){
    function goTo(selector){
        document.querySelector(selector).scrollIntoView();
    }
    return(
        <div className="footer">
            <div className="footer__container">
                <img onClick={()=>goTo('.intro')} src={logo} alt=""/>
                <div className="footer__links">
                    <a onClick={()=>goTo('.intro')}>Heure</a>
                    <a onClick={()=>goTo('.mapZone')}>Position</a>
                    <a onClick={()=>goTo('.equipage')}>Equipage</a>
                </div>
            </div>
        </div>
    )
}